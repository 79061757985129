import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AdventureStyleDTO,
  AdventureStyleMngtControllerService,
  LPSectionItemDTO,
} from '@assistant/angular-travel-admin-service';
import { FormControl } from '@angular/forms';
import { DialogConfig } from '@core/models/interfaces/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { LPSectionAdminViewDTO } from '@assistant/angular-travel-admin-service/model/lPSectionAdminViewDTO';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-adventure-styles',
  templateUrl: './adventure-styles.component.html',
  styleUrls: ['./adventure-styles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdventureStylesComponent implements OnInit {
  @Input() adventureStylesSection: LPSectionAdminViewDTO | undefined;
  prefix: string = 'landing-page-content.';
  listLangsHeading: string[] = [];
  activeLangHeading: string = '';
  listLangsContent: string[] = [];
  activeLangContent: string = '';
  listAdventure: AdventureStyleDTO[] = [];
  listAdventureOrigin: AdventureStyleDTO[] = [];
  selectedAdventures: AdventureStyleDTO[] = [];
  isShowAddAdventureModal = false;
  heading = new FormControl();
  content = new FormControl();
  globalFilterFields: string[] = ['name'];
  addAdventureModalConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'rounded-xl min-w-full lg:min-w-[792px]',
    appendTo: 'body',
    cancelBtn: false,
    confirmBtn: false,
  };
  maxValue = 20;

  constructor(
    private httpClient: HttpClient,
    private adventureMngtControllerService: AdventureStyleMngtControllerService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['adventureStylesSection']) {
      this.adventureStylesSection =
        changes['adventureStylesSection'].currentValue;
      if (this.adventureStylesSection) {
        this.listLangsHeading = Object.keys(
          this.adventureStylesSection.heading || {},
        );
        this.activeLangHeading = this.listLangsHeading[0];
        this.heading.setValue(
          this.adventureStylesSection.heading
            ? this.adventureStylesSection.heading[this.listLangsHeading[0]]
            : '',
        );

        this.listLangsContent = Object.keys(
          this.adventureStylesSection.content || {},
        );
        this.activeLangContent = this.listLangsContent[0];
        this.content.setValue(
          this.adventureStylesSection.content
            ? this.adventureStylesSection.content[this.listLangsContent[0]]
            : '',
        );
        this.listAdventure =
          this.listAdventure.filter(
            (adventure) =>
              !this.adventureStylesSection?.items?.some(
                (section) => section.id === adventure.id,
              ),
          ) ?? [];
      }
    }
  }

  ngOnInit(): void {
    this.fetchAdventureStyleList();
  }

  fetchAdventureStyleList(): void {
    this.adventureMngtControllerService
      .searchAdventureStyle(undefined, true, undefined, 0, 5000)
      .subscribe((res) => {
        if (res.success && res.data) {
          this.listAdventure = res.data?.data ?? [];
          this.listAdventureOrigin = res.data?.data ?? [];
          if (
            this.adventureStylesSection &&
            this.adventureStylesSection.items
          ) {
            this.listAdventure =
              res.data?.data?.filter(
                (adventure) =>
                  !this.adventureStylesSection?.items?.some(
                    (section) => section.id === adventure.id,
                  ),
              ) ?? [];
          } else {
            this.listAdventure = res.data?.data ?? [];
          }
        } else {
          this.listAdventure = [];
          this.listAdventureOrigin = [];
        }
      });
  }

  onSearch(event: Event, dt: Table): void {
    const searchValue = (event.target as HTMLInputElement).value;
    dt.filterGlobal(searchValue, 'contains'); // Sử dụng phương thức filterGlobal của bảng
  }

  closeAdventureForm(): void {
    this.isShowAddAdventureModal = false;
    this.selectedAdventures = [];
  }

  addAdventure() {
    if (this.adventureStylesSection && !this.adventureStylesSection.items) {
      this.adventureStylesSection.items = [];
    }
    this.selectedAdventures.map((item) => {
      this.adventureStylesSection?.items?.push({
        id: item.id,
        title: item.name,
        image: item.images ? item.images[0] : undefined,
        localized_name: item.localized_name,
        is_active: false,
        extraData: null,
      });
      this.listAdventure = this.listAdventure.filter(
        (adventure) => adventure.id !== item.id,
      );
    });
    this.isShowAddAdventureModal = false;
    this.selectedAdventures = [];
  }

  changeLangHeading(lang: any) {
    if (this.adventureStylesSection) {
      this.activeLangHeading = lang;
      this.heading.setValue(
        this.adventureStylesSection.heading
          ? this.adventureStylesSection.heading[lang]
          : '',
      );
    }
  }

  changeLangContent(lang: any) {
    if (this.adventureStylesSection) {
      this.activeLangContent = lang;
      this.content.setValue(
        this.adventureStylesSection.content
          ? this.adventureStylesSection.content[lang]
          : '',
      );
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.adventureStylesSection?.items!,
      event.previousIndex,
      event.currentIndex,
    );
  }

  inputHeading() {
    if (!this.adventureStylesSection) {
      return;
    }
    if (!this.adventureStylesSection.heading) {
      this.adventureStylesSection.heading = {};
    }
    this.adventureStylesSection.heading[this.activeLangHeading] =
      this.heading.value;
  }

  inputContent() {
    if (!this.adventureStylesSection) {
      return;
    }
    this.adventureStylesSection.content =
      this.adventureStylesSection.content ?? {};
    this.adventureStylesSection.content[this.activeLangContent] =
      this.content.value;
  }

  removeAdventureStyle(index: number, id: string) {
    this.adventureStylesSection?.items?.splice(index, 1);
    const adventure = this.listAdventureOrigin.find((item) => item.id === id);
    if (adventure) {
      this.listAdventure.push(adventure);
    }
  }

  onFileReplace($event: any, adventureStyle: LPSectionItemDTO) {
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            const selectedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };
            adventureStyle.image = selectedFile;
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {},
      });
  }

  checkKeys(inputObject: { [key: string]: string }, keys: string[]): boolean {
    return keys.every((key) => key in inputObject && inputObject[key] !== '');
  }

  disableCheckboxItem(adventure: AdventureStyleDTO): boolean {
    const isSelected = this.selectedAdventures.includes(adventure);
    if (
      this.selectedAdventures.length ===
        this.maxValue - this.adventureStylesSection?.items?.length! &&
      !isSelected
    ) {
      return true;
    }
    return false;
  }
}
