import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  LPSectionAdminViewDTO,
  LPSectionItemDTO,
  TourLabelControllerService,
  TourLabelDTO,
} from '@assistant/angular-travel-admin-service';
import { DialogConfig } from '@core/models/interfaces/dialog';
import { environment } from '@env/environment';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-label-section',
  templateUrl: './label-section.component.html',
  styleUrls: ['./label-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabelSectionComponent implements OnInit {
  @Input() labelSection: LPSectionAdminViewDTO | undefined = undefined;
  prefix: string = 'landing-page-content.';
  listTourLabel: TourLabelDTO[] = [];
  listTourLabelOrigin: TourLabelDTO[] = [];
  selectedLabels: TourLabelDTO[] = [];
  isShowAddLabelModal = false;
  globalFilterFields: string[] = ['default_name', 'default_description'];
  addLabelModalConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'rounded-xl min-w-full lg:min-w-[792px]',
    appendTo: 'body',
    cancelBtn: false,
    confirmBtn: false,
  };
  maxValue = 20;

  constructor(
    private labelControllerService: TourLabelControllerService,
    private httpClient: HttpClient,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['labelSection']) {
      this.labelSection = changes['labelSection'].currentValue;
      this.listTourLabel =
        this.listTourLabel.filter(
          (label) =>
            !this.labelSection?.items?.some(
              (section) => section.id === label.id,
            ),
        ) ?? [];
    }
  }

  ngOnInit(): void {
    this.getListLabel();
  }

  getListLabel(): void {
    this.labelControllerService
      .searchTourLabel(undefined, undefined, undefined, true, 0, 5000)
      .subscribe((res) => {
        if (res.success && res.data) {
          this.listTourLabelOrigin = res.data?.data ?? [];
          if (this.labelSection && this.labelSection.items) {
            this.listTourLabel =
              res.data?.data?.filter(
                (label) =>
                  !this.labelSection?.items?.some(
                    (section) => section.id === label.id,
                  ),
              ) ?? [];
          } else {
            this.listTourLabel = res.data?.data ?? [];
          }
        } else {
          this.listTourLabel = [];
          this.listTourLabelOrigin = [];
        }
      });
  }

  dropItem(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.labelSection?.items!,
      event.previousIndex,
      event.currentIndex,
    );
  }

  onSearch(event: Event, dt: Table): void {
    const searchValue = (event.target as HTMLInputElement).value;
    dt.filterGlobal(searchValue, 'contains'); // Sử dụng phương thức filterGlobal của bảng
  }

  closeLabelForm(): void {
    this.isShowAddLabelModal = false;
    this.selectedLabels = [];
  }

  AddLabel(): void {
    if (this.labelSection && !this.labelSection?.items) {
      this.labelSection.items = [];
    }
    this.selectedLabels.map((item) => {
      this.labelSection?.items?.push({
        id: item.id,
        title: item.default_name,
        image: item.image,
        localized_name: item.name,
        is_active: false,
        extraData: null,
      });
      this.listTourLabel = this.listTourLabel.filter(
        (label) => label.id !== item.id,
      );
    });
    this.isShowAddLabelModal = false;
    this.selectedLabels = [];
  }

  removeLabel(index: number, id: string): void {
    this.labelSection?.items?.splice(index, 1);
    const label = this.listTourLabelOrigin.find((item) => item.id === id);
    if (label) {
      this.listTourLabel.push(label);
    }
  }

  onFileReplace($event: any, label: LPSectionItemDTO): void {
    const formData = new FormData();
    formData.append('file', $event?.target?.files[0]);
    this.httpClient
      .post(`${environment.baseURL}/storage/files/upload-file`, formData)
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            const selectedFile = {
              id: res.data.id,
              extension: res.data.extension,
              type: res.data.type,
              description: res.data.description,
              original_name: res.data.original_name,
            };
            label.image = selectedFile;
          } else {
            console.log(res.message);
          }
        },
        error: (err) => {},
      });
  }

  disableCheckboxItem(label: LPSectionItemDTO): boolean {
    const isSelected = this.selectedLabels.includes(label);
    if (
      this.selectedLabels.length ===
        this.maxValue - this.labelSection?.items?.length! &&
      !isSelected
    ) {
      return true;
    }
    return false;
  }
}
