<div class="t-label-section grid bg-white rounded-lg p-4 gap-4 mt-3">
  <div class="flex flex-row justify-between">
    <div class="grid">
      <div>
        <h4 class="text-gray-900 font-semibold text-xl flex flex-row gap-1">
          {{ prefix + "label-section" | translate }}
          <p class="text-[14px] text-gray-400 font-medium">
            ({{ labelSection?.items?.length || 0 }}/{{ maxValue }})
          </p>
        </h4>
        <p class="text-[14px] text-gray-700">
          {{ prefix + "quantity-of-items" | translate }}
        </p>
      </div>
      <p
        *ngIf="labelSection && labelSection?.items?.length! >= maxValue"
        class="text-[14px] text-warning-500"
      >
        {{ prefix + "maximum-quantity" | translate }}
      </p>
    </div>

    <button
      class="bg-blue-600 h-10 rounded-lg text-white p-2 text-[14px] flex gap-1 justify-center"
      [ngClass]="
        labelSection?.items?.length! >= maxValue
          ? 'bg-gray-300'
          : 'bg-blue-600 hover:bg-blue-500'
      "
      (click)="isShowAddLabelModal = true"
      [disabled]="labelSection?.items?.length! >= maxValue"
    >
      <i class="sctr-icon-plus-circle pt-1"></i>
      {{ prefix + "add-item" | translate }}
    </button>
  </div>
  <div>
    <div
      cdkDropList
      class="grid rounded-xl"
      (cdkDropListDropped)="dropItem($event)"
    >
      <div
        class="gird gap-3 p-1 rounded-xl"
        *ngFor="let label of labelSection?.items; let i = index"
        cdkDrag
      >
        <div class="flex h-[96px] gap-2 rounded-xl">
          <i class="sctr-icon-dots-grid"></i>
          <div class="flex gap-1 bg-gray-200 rounded-xl border p-2">
            <div class="relative inline-block">
              <img
                class="w-[160px] h-[80px] rounded-xl object-cover"
                [src]="label.image?.id! | image"
                alt="adventure style"
              />
              <!-- <div class="flex gap-1 justify-between">
                <input
                  id="{{ label.id }}"
                  type="file"
                  (change)="onFileReplace($event, label)"
                  accept=".jpg, .jpeg, .png"
                  hidden
                />
                <label
                  for="{{ label.id }}"
                  class="sctr-icon-upload-cloud-02 text-white text-[24px] font-medium absolute top-0 right-0 m-1 cursor-pointer"
                >
                </label>
              </div> -->
            </div>
            <textarea
              class="w-[700px] h-[80px] border border-gray-300 rounded-md p-2"
              placeholder="Enter description"
              rows="4"
              [value]="label.title"
            ></textarea>
          </div>
          <div class="flex gap-3">
            <p-inputSwitch
              [(ngModel)]="label.is_active"
              class="flex"
            ></p-inputSwitch>
            <button
              [disabled]="label.extraData?.total_tours > 0"
              (click)="removeLabel(i, label?.id ?? '')"
              class="sctr-icon-trash-01 flex text-[20px]"
              [ngClass]="
                label.extraData?.total_tours > 0
                  ? 'text-gray-300'
                  : 'text-gray-500'
              "
              [pTooltip]="
                label.extraData?.total_tours > 0
                  ? (prefix + 'being-used in-tours' | translate)
                  : ''
              "
              tooltipPosition="right"
            ></button>
          </div>
          <div class="custom-placeholder" *cdkDragPlaceholder></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  [showDialog]="isShowAddLabelModal"
  (emitOnCloseDialog)="closeLabelForm()"
  [dialogConfig]="addLabelModalConfig"
  [showFooterDialog]="false"
>
  <div modal-header class="font-Inter flex flex-col gap-1">
    <h5 class="text-lg font-semibold text-gray-900">
      {{ prefix + "add-label" | translate }}
    </h5>
    <p class="text-[14px] text-warning-500">
      {{
        prefix + "maximum-item-select"
          | translate
            : {
                param: maxValue - (this.labelSection?.items?.length ?? 0)
              }
      }}
    </p>
  </div>
  <div modal-content class="font-Inter flex flex-col gap-2">
    <p-table
      #dt
      dataKey="id"
      [value]="listTourLabel"
      [rowHover]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [paginator]="true"
      styleClass="text-md/none overflow-x-auto"
      class="t-table-lable"
      [globalFilterFields]="globalFilterFields"
      [(selection)]="selectedLabels"
      [totalRecords]="listTourLabel.length"
    >
      <ng-template pTemplate="caption">
        <div class="flex bg-white">
          <span class="p-input-icon-left bg-white px-0">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="onSearch($event, dt)"
              [placeholder]="
                'lable-management.search_by_tour_lable' | translate
              "
              class="t-search-input w-full h-10 font-Inter"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox
              [disabled]="
                listTourLabel.length > maxValue - labelSection?.items?.length!
              "
            ></p-tableHeaderCheckbox>
          </th>
          <th class="w-[35%] whitespace-nowrap">
            {{ "lable-management.lable-name" | translate }}
          </th>
          <th class="w-[60%] whitespace-nowrap">
            {{ "lable-management.lable-description" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-label>
        <tr>
          <td>
            <p-tableCheckbox
              [value]="label"
              [disabled]="disableCheckboxItem(label)"
            ></p-tableCheckbox>
          </td>
          <td>
            <div class="flex flex-row items-center gap-3">
              <img
                class="w-[32px] h-[32px] rounded object-cover"
                [src]="label.image.id ? (label.image.id | image) : ''"
                alt="adventure style"
              />
              <p class="whitespace-nowrap" [pTooltip]="label.default_name">
                {{ label.default_name }}
              </p>
            </div>
          </td>
          <td>
            <p class="whitespace-nowrap">{{ label.default_description }}</p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="9">
            <no-result-found></no-result-found>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div modal-footer class="justify-end flex gap-2">
    <button
      class="bg-white rounded-lg text-gray900 w-24 hover:bg-gray-100 p-2 text-[14px] align-middle content-center justify-center flex gap-1 border border-gray-400"
      (click)="closeLabelForm()"
    >
      {{ "lable-management.cancel" | translate }}
    </button>
    <button
      class="bg-blue-600 hover:bg-blue-500 rounded-lg text-white p-2 text-[14px] align-middle content-center flex gap-1 w-20 justify-center items-center"
      (click)="AddLabel()"
      [ngClass]="
        selectedLabels.length == 0
          ? 'bg-gray-300'
          : 'bg-blue-600 hover:bg-blue-500'
      "
      [disabled]="selectedLabels.length === 0"
    >
      {{ "lable-management.submit" | translate }}
    </button>
  </div>
</app-modal>
